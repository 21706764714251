.post-individual {

	margin-top: 1.5rem;
	padding-bottom: 2rem;
	padding-left: 1rem;

	.post-header {
		width: 100%;
		display: flex;
		align-items: flex-start;
		margin-bottom: $global-margin;

		.article-header-avatar {
			padding-right: 0.5rem;
			padding-left: 0.5rem;

			.header-avatar {
				width: rem-calc(60);
				height: rem-calc(60);
				border-radius: 50%;
			} //.header-avatar
		} // .article-header-avatar

		.article-header-author {
			flex: 1 0 0;

			.author-name {
				color: $black;
				margin-bottom: 0;
			}

			.author-description,
			.article-date-read {
				color: $dark-gray;
				margin-bottom: 0;
				font-size: rem-calc(0.85em);
			}  //.author-description, .article-date-read
		} //.article-header-author
		
	} //.post-header
	
	.post-title {
		margin-bottom: $global-margin*2;

		.airbnb {
			display: block;
			font-size: 50%;
			
			@include breakpoint (small only)
			{
				font-size: 75%;
			}
			
			.map
			{
				padding-left: 3rem;
			}
		}	
	} //.post-title
	
	.newspaper
	{
		margin-bottom: 1rem;
		
		@include breakpoint (large)
		{
			column-count: 2;
		}
	} //.newspaper
} //.post-individual
