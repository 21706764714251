/*!
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ Software : France 2018 Vacation — A Website Built on Foundation              ┃
┃ Version  : v1.0.7  development build: 2019-03-25 09:42:45                    ┃
┃ Hostname : france.werbyfamily.com                                            ┃
┃ Support  : info@pipsqueak.com • 415/668-4372 • https://pipsqueak.com         ┃
┠──────────────────────────────────────────────────────────────────────────────┨
┃ Author          : Christopher Werby; Pipsqueak Productions, LLC              ┃
┃ Client          : Pipsqueak Personal Project                                 ┃
┃ Product Manager : Christopher Werby <info@pipsqueak.com>                     ┃
┠──────────────────────────────────────────────────────────────────────────────┨
┃ Copyright  © 2018 to 2019, Pipsqueak Productions, LLC. All Rights Reserved.  ┃
┠──────────────────────────────────────────────────────────────────────────────┨
┃ Pipsqueak offers a variety of creative services to its clients:              ┃
┃ • websites • programming • animation • production • photography              ┃
┃ • graphics • interfaces • ux • strategy • writing • branding                 ┃
┃ • design • print • education • ads                                           ┃
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/

@charset 'utf-8';

//2018-09-21 Pipsqueak commented out the unused Foundation SCSS modules. Note that three were already commented out by default: foundation-range-input, foundation-float-classes, foundation-prototype-classes
//2018-09-26 Added uncss ignore tags to foundation-xy-grid-classes so that 3-up images would work.


@import 'settings';
@import 'foundation';

//@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
//

/*! uncss:ignore start */
	@include foundation-xy-grid-classes;
/*! uncss:ignore end */

@include foundation-typography;
@include foundation-button;
//@include foundation-forms;
// @include foundation-range-input; //was commented out by default
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
@include foundation-media-object;

/*! uncss:ignore start */
	@include foundation-off-canvas;
	@include foundation-pagination;
/*! uncss:ignore end */

//@include foundation-orbit;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes; //was commented out by default
@include foundation-flex-classes;

// @include foundation-prototype-classes;

//@include motion-ui-transitions;
//@include motion-ui-animations;

// vendor styles
//@import "vendor/aos";
//@import "vendor/slick-carousel";
//@import "vendor/index-menu";

/*! uncss:ignore start */
	@import 'vendor/jquery.fancybox';
/*! uncss:ignore end */


// mixins
//@import 'mixins/container-border';
//@import 'mixins/flex';
//@import 'mixins/avatar';
//@import 'mixins/helpers';
//@import 'mixins/pseudo';
//@import 'mixins/push-center';
//@import 'mixins/sidelines';
//@import 'mixins/zindex';

// global styles
@import 'global/layout';
@import 'global/typography';

// custom components

//Pipsqueak Custom Components
/*! uncss:ignore start */
@import 'components/images-container';
@import 'components/post-layout';
@import 'components/post-individual';
@import 'components/post-list';
/*! uncss:ignore end */

