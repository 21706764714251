.post-list
{
	ul
	{
		padding-left: 0;
		margin-left: 0;
		list-style-type: none;	
	}
	
	li
	{
		margin-bottom: 2rem;
		width: 220px;
		
		&.active
		{
			background-color: #2E2C61; //blue
			
			.post-text
			{
				color: white;
			}			
		
			img
			{
				border-bottom: 2px black solid;
				outline: 10px solid;
				outline-color: rgba(255, 165, 0, .6); //color orange
				outline-offset: -10px;
			}
		}
		
		small
		{
			line-height: 1rem;
			display: block;
		}
	}
	
	img
	{
		height: 220px;
		width: 220px;
		object-fit: cover;
	}
	
	.post-text
	{
		padding-left: 10px;
	}
} //.post-list