.image-container {

	margin-bottom: $global-margin;

	p
	{
		// caption
		font-weight: bold;
		text-align: center;
	}
		
	.image-container-image {
		position: relative;
		display: inline-block;
			
		&.noFrame
		{
			img
			{
				box-shadow: none;
				border: none;
			}
			
			span.credit
			{
				display: none;
			}
		} //&.noFrame
	
		img
		{
			padding: 10px;
			line-height: 1.42857143;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 3px;
			transition: all .2s ease-in-out;
			display: inline-block;
			max-width: 100%;
			height: auto;
			box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
			box-shadow: 2px 2px 5px #DDD;
			margin-bottom: 5px;
		} //img
		
		span.credit
		{
			color: #CCC;
			display: block;
			min-width: 100px;
			font-size: 35%;
			text-align: right;
			transform: rotate(90deg);
			position: absolute;
			right: -45px; //was -25px;
			//z-index: 100;
			bottom: 70px; //was 20%
		} //span.credit
	} //.image-container-image
} //.image-container

.images-container {
		
	margin-bottom: $global-margin;
	
	.full-post-container-image-block {
		margin-bottom: 0;
	}
	
	p.caption
	{
		// caption
		font-weight: bold;
		text-align: center;
	}
	
	div.left
	{
		margin-right: $global-margin;
	}
	
	div.right
	{
		margin-left: $global-margin;
	}
} //.images-container
	
.images-container-caption-top, .images-container.removeBottom {
	margin-bottom: 0;
}

.image-container, .images-container {	
	p.caption-top {
		margin-bottom: .2rem;
	}
} //.image-container, .images-container