//Pipsqueak Overrides

@media screen and (max-width: 39.9375em) {
  .pagination:after 
  	{
  		//by default, there is an errant " of " in the middle of the paginator.
		content: "";
	}
}

.nobreak {
	white-space: nowrap;
}