.topper
{
	//ref: https://uigradients.com/#Lawrencium
	background: #0f0c29;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #24243e, #302b63, #0f0c29);
	background: linear-gradient(to bottom, #24243e, #302b63, #0f0c29);
}

#dayList
{
	width: 250px;
	margin-left: 0px;

} //#dayList

.footer
{
	padding-top: 20px;
	padding-bottom: 5px;
	background: #EEE;
}